import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import gql from "graphql-tag";
export var GET_ORDER = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query($id: Int!) {\n    orderDetail(id: $id) {\n      isSuccess\n      message\n      record {\n        _id\n        orderId\n        orderCode\n        ship_by\n        shipping_price\n        serviceCube\n        serviceKg\n        cancel_remark\n        remark\n        status\n        statusShow\n        currency\n        currencySign\n        user {\n          username\n        }\n        total\n        rate\n        total_baht\n        created_at\n        pay_date\n        transactions {\n          amount\n          reason\n          doc_id\n          created_at\n        }\n        transports {\n          _id\n          transportId\n          transportNumber\n          remark\n          created_at\n          payshipping_id\n          payshipping_doc\n          ship_by\n          guarantee\n          no\n          timeline {\n            send_to_warehouse_china\n            send_out_china\n            send_to_warehouse_th\n            payshipping\n            sent_out_to_customer\n          }\n        }\n        vendors {\n          _id\n          vendor\n          provider\n          guarantee\n          qc\n          shipping_price\n          currency\n          currencySign\n          remark\n          items {\n            _id\n            mainImage\n            productName\n            url\n            price\n            amount_buy\n            amount\n            remark\n            remarkForCustomer\n            file_attachment\n            currency\n            currencySign\n            properties {\n              id\n              name\n              title\n              image\n            }\n          }\n        }\n      }\n    }\n  }\n"])));
export var GET_ORDERS = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query(\n    $pageSize: Int!\n    $page: Int!\n    $status: String\n    $ship_by: String\n    $search: String\n  ) {\n    orderList(\n      pageSize: $pageSize\n      page: $page\n      status: $status\n      ship_by: $ship_by\n      search: $search\n    ) {\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        orderId\n        orderCode\n        ship_by\n        remark\n        status\n        statusShow\n        item_count\n        total\n        rate\n        total_baht\n        created_at\n        itemsLess {\n          _id\n          mainImage\n          productName\n          url\n          price\n          amount_buy\n          amount\n          properties {\n            id\n            name\n            title\n            image\n          }\n        }\n      }\n    }\n  }\n"])));
export var CHECKOUT_ORDER = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($input: ChecoutOderInput!) {\n    checkoutOrder(input: $input) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var CANCEL_ORDER = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($id: Int, $remark: String) {\n    orderDetailReject(id: $id, remark: $remark) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var UPDATE_ORDER_REMARK = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation($id: ID, $remark: String) {\n    updateOrder(input: { _id: $id, remark: $remark }) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var PAY_ORDER = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation($id: Int) {\n    payOrder(id: $id) {\n      isSuccess\n      message\n    }\n  }\n"])));